import { useEffect, useState } from 'react';
import { useQuery } from 'urql';
import { Link } from 'react-router-dom';

import notification from '../notification';
import HTMLEngine from '../HTMLEngine';

import { User } from '@biosub/common/User';
import { BookForPublic } from '@biosub/common/Book';
import { Subscription } from '@biosub/common/Subscription';

import { Layout } from '../components/_layout';

import logo from '../assets/LOGO_BIO_2.png';

export const Home: React.FC = () => {
    const [currentUserQuery] = useQuery<{ me: User }>({
        query: /* GraphQL */ `
            query {
                me {
                    firstname
                    lastname
                }
            }
        `
    });

    const [allBooksQuery] = useQuery<{ books: BookForPublic[] }>({
        query: /* GraphQL */ `
            query {
                books {
                    slug
                    title
                    description
                    cover
                    authors
                    keywords
                }
            }
        `
    });

    const [subscriptionQuery] = useQuery<{ subscription: Subscription }>({
        query: /* GraphQL */ `
            query {
                subscription {
                    items {
                        book {
                            slug
                        }
                    }
                }
            }
        `
    });

    const [books, setBooks] = useState<BookForPublic[]>();
    const [searchTerm, setSearchTerm] = useState<string>();

    useEffect(() => {
        if (!allBooksQuery.fetching && allBooksQuery.data) {
            const tmp: BookForPublic[] = allBooksQuery.data.books;

            if (searchTerm && searchTerm.length > 2) {
                setBooks(
                    tmp.filter(
                        t =>
                            t.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            t.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            t.authors.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            t.keywords.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                );
            } else {
                setBooks(tmp);
            }
        }
    }, [allBooksQuery, searchTerm]);

    const toggleDescription = (i: number) => {
        const desc = document.getElementById(`book_${i}`);
        desc?.classList.toggle('open');
    };

    if (currentUserQuery.error || allBooksQuery.error || subscriptionQuery.error) {
        notification.error(currentUserQuery.error || allBooksQuery.error || subscriptionQuery.error || 'Si è verificato un errore inaspettato');
        return <></>;
    }

    return (
        <Layout title="Benvenuti">
            <h1>
                Benvenuto {currentUserQuery.data?.me.firstname} {currentUserQuery.data?.me.lastname}
            </h1>
            <div className="mt-4">Ecco tutte le pubblicazioni disponibili delle rivista Bioetica</div>

            <div className="mt-4 flex justify-end">
                <div className="flex items-center bg-gray-200 text-gray-400 p-2">
                    <i className="las la-search la-2x"></i>
                    <input className="input small" placeholder="Cerca" onChange={e => setSearchTerm(e.target.value)} />
                </div>
            </div>

            {books && books.length > 0 ? (
                books?.map((book, i) => (
                    <div key={i} className="mt-4 flex flex-col md:flex-row border" style={{ backgroundColor: '#f5f5f5' }}>
                        <div className="w-full md:w-1/4 p-4 flex flex-col bg-white">
                            <img src={book.cover || logo} className="w-28" alt={book.title} title={book.title} />
                            <div className="mt-2 font-bold text-xl">{book.title}</div>

                            {subscriptionQuery.data?.subscription?.items.some(b => b.book.slug === book.slug) === true && (
                                <Link to="/subscription" className="mt-4 flex items-center text-green-600">
                                    <i className="las la-check-circle la-2x mr-2"></i>
                                    Hai articoli di questa pubblicazione disponibili nel tuo abbonamento
                                </Link>
                            )}
                        </div>
                        <div id={`book_${i}`} className="md:flex-1 p-4 description-box">
                            <h5>Descrizione</h5>
                            <div className="mt-2" dangerouslySetInnerHTML={{ __html: new HTMLEngine().render(book.description) }} />

                            <div className="toggle flex justify-center items-end pb-4">
                                <button className="bg-bioetica-red p-2 text-white font-bold uppercase text-xs" onClick={() => toggleDescription(i)}>
                                    Leggi di più
                                </button>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <h3>Nessun libro disponbile</h3>
            )}
        </Layout>
    );
};
