import { useForm } from 'react-hook-form';
import { useQuery } from 'urql';
import { updatePassword } from '@firebase/auth';
import { FirebaseError } from 'firebase/app';

import firebase from '../firebase';

import notification from '../notification';

import { User } from '@biosub/common/User';

import { Layout } from '../components/_layout';

type Form = {
    newPassword: string;
    confirmNewPassword: string;
};

export const Me: React.FC = () => {
    const [currentUserQuery] = useQuery<{ me: User }>({
        query: /* GraphQL */ `
            query {
                me {
                    firstname
                    lastname
                    email
                }
            }
        `
    });

    const {
        handleSubmit,
        register,
        watch,
        setValue,
        formState: { errors }
    } = useForm<Form>();

    const onSubmit = async (input: Form) => {
        try {
            if (firebase.currentUser) {
                updatePassword(firebase.currentUser, input.newPassword);

                setValue('newPassword', '');
                setValue('confirmNewPassword', '');

                notification.success('Password cambiata con successo!');
            }
        } catch (e) {
            if (e instanceof FirebaseError || e instanceof Error) {
                notification.error(e);
            }
        }
    };

    if (currentUserQuery.error) {
        notification.error(currentUserQuery.error);
        return <></>;
    }

    return (
        <Layout title="Benvenuti">
            <h1>Il tuo account</h1>

            <div className="mt-8 flex flex-col text-xl border border-gray-300">
                <div className="flex">
                    <div className="w-36 p-3 text-right bg-gray-100 border-r border-b border-gray-300">Nome:</div>
                    <div className="flex-1 p-3 border-b border-gray-300">{currentUserQuery.data?.me.firstname}</div>
                </div>
                <div className="flex">
                    <div className="w-36 p-3 text-right bg-gray-100 border-r border-b border-gray-300">Cognome:</div>
                    <div className="flex-1 p-3 border-b border-gray-300">{currentUserQuery.data?.me.lastname}</div>
                </div>
                <div className="flex">
                    <div className="w-36 p-3 text-right bg-gray-100 border-r border-gray-300">Email:</div>
                    <div className="flex-1 p-3">{currentUserQuery.data?.me.email}</div>
                </div>
            </div>

            <h3 className="mt-8">Cambia password</h3>
            <p className="mt-2">
                Crea una nuova password per il tuo account.
                <br />
                La nuova password deve essere di almeno 6 caratteri e deve contenere una cifra, una lettera maiuscola e un carattere speciale (@, &, !, ?, etc...)
            </p>

            <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
                <label className="py-2 font-bold uppercase" htmlFor="password">
                    Nuova password
                </label>
                <input
                    type="password"
                    {...register('newPassword', {
                        required: 'La password è obbligatoria',
                        pattern: {
                            value: /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1})/,
                            message: 'La password deve essere di almeno 6 caratteri e deve contenere una cifra, una lettera maiuscola e un carattere speciale (@, &, !, ?, etc...)'
                        }
                    })}
                    className="input"
                />
                {errors.newPassword && <div className="input-error">{errors.newPassword.message}</div>}

                <div className="mt-4">
                    <label className="py-2 font-bold uppercase" htmlFor="confirmNewPassword">
                        Conferma nuova password
                    </label>
                    <input
                        type="password"
                        {...register('confirmNewPassword', {
                            validate: value => value === watch('newPassword') || 'La password e la conferma non coincidono'
                        })}
                        className="input"
                    />
                    {errors.confirmNewPassword && <div className="input-error">{errors.confirmNewPassword.message}</div>}
                </div>

                <button type="submit" className="mt-8 button w-full green">
                    Cambia password
                </button>
            </form>
        </Layout>
    );
};
