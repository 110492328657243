import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { pipe, tap } from 'wonka';
import { Provider, cacheExchange, createClient, dedupExchange, Exchange, fetchExchange } from 'urql';

import { AuthProvider } from './contexts/auth';
import App from './App';

export const errorExchange: Exchange =
    ({ forward }) =>
    ops$ => {
        return pipe(
            forward(ops$),
            tap(({ error }) => {
                console.log('NON FUNZIONAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA PERCHE 500???');
                const isAuthError = error?.graphQLErrors.some(e => e.extensions?.code === 'UNAUTHENTICATED' || e.message === 'UNAUTHENTICATED');
                if (isAuthError) {
                    sessionStorage.removeItem('token');
                    window.location.href = `/login?returnUrl=${encodeURI(window.location.pathname)}`;
                }
            })
        );
    };

const client = createClient({
    url: process.env.REACT_APP_BASE_URL as string,
    fetchOptions: () => {
        return {
            headers: { authorization: `Bearer ${sessionStorage.getItem('token')}` }
        };
    },
    exchanges: [fetchExchange, cacheExchange, dedupExchange, errorExchange]
});

const root = createRoot(document.getElementById('root') as Element);
root.render(
    <StrictMode>
        <AuthProvider>
            <Provider value={client}>
                <App />
            </Provider>
        </AuthProvider>
    </StrictMode>
);
