import { useQuery } from 'urql';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { FirebaseError } from 'firebase/app';
import { saveAs } from 'file-saver';

import notification from '../notification';

import HTMLEngine from '../HTMLEngine';

import { User } from '@biosub/common/User';
import { Subscription } from '@biosub/common/Subscription';
import { GQLFile } from '@biosub/common/GQLFile';

import { Layout } from '../components/_layout';

import logo from '../assets/LOGO_BIO_2.png';

export const UserSubscription: React.FC = () => {
    const [currentUserQuery] = useQuery<{ me: User }>({
        query: /* GraphQL */ `
            query {
                me {
                    firstname
                    lastname
                }
            }
        `
    });

    const [subscriptionQuery] = useQuery<{ subscription: Subscription }>({
        query: /* GraphQL */ `
            query {
                subscription {
                    no
                    items {
                        book {
                            title
                            description
                            cover {
                                url
                            }
                        }
                        articles {
                            title
                            abstract
                            authors
                            pdfs {
                                filename
                                url
                            }
                        }
                    }
                }
            }
        `
    });

    const download = async (file: GQLFile) => {
        try {
            const storage = getStorage();
            const protectedPDFFile = await getDownloadURL(ref(storage, file.url));

            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = () => {
                saveAs(xhr.response, file.filename);
            };
            xhr.open('GET', protectedPDFFile);
            xhr.send();
        } catch (e) {
            if (e instanceof FirebaseError || e instanceof Error) {
                notification.error(e);
            }
        }
    };

    if (currentUserQuery.error || subscriptionQuery.error) {
        notification.error(currentUserQuery.error || subscriptionQuery.error || 'Si è verificato un errore inaspettato');
        return <></>;
    }

    return (
        <Layout title="Benvenuti">
            <h2>
                Il tuo abbonamento <i>{subscriptionQuery.data?.subscription?.no}</i>
            </h2>
            <div className="mt-4">
                Intestato a {currentUserQuery.data?.me.firstname} {currentUserQuery.data?.me.lastname}
            </div>

            {subscriptionQuery.data?.subscription ? (
                <>
                    {subscriptionQuery.data.subscription.items.map((si, i) => (
                        <div key={i} className="flex mt-4 bg-gray-200 p-4">
                            <img src={si.book.cover?.url || logo} className="h-64" alt={si.book.title} title={si.book.title} />
                            <div className="flex-1 ml-4 p-4 bg-white">
                                <h2>{si.book.title}</h2>
                                <p className="mt-2" dangerouslySetInnerHTML={{ __html: new HTMLEngine().render(si.book.description) }} />

                                <div className="mt-8">
                                    <b>Articoli disponibili nel tuo abbonamento:</b>
                                    {si.articles.map((a, j) => (
                                        <div key={j} className="mt-2">
                                            <h4>{a.title}</h4>
                                            <p className="mt-2 font-bold">Abstract:</p>
                                            <p dangerouslySetInnerHTML={{ __html: new HTMLEngine().render(a.abstract) }} />
                                            <p className="mt-2 font-bold">Autori:</p>
                                            <p>{a.authors}</p>
                                            <p className="mt-2 font-bold">PDF disponibili per il download:</p>
                                            <ul className="mt-2 pl-6 list-disc">
                                                {a.pdfs?.map((p: GQLFile, j: number) => (
                                                    <li key={j}>
                                                        <button className="underline" onClick={() => download(p)}>
                                                            {p.filename}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                <h3 className="mt-8">Nessun abbonamento attualmente attivo</h3>
            )}
        </Layout>
    );
};
