type Paragraph = { paragraph: (data: { text: string }) => string };
type Header = { header: (data: { level: string; text: string }) => string };
type List = { list: (data: { style: string; items: string[] }) => string };

type Block = {
    type: 'paragraph' | 'header' | 'list';
    data: { text: string } & { level: string; text: string } & { style: string; items: string[] };
};

export default class HTMLEngine {
    static get generators(): Paragraph & Header & List {
        return {
            paragraph: (data: { text: string }) => `<p>${data.text}</p>`,

            header: (data: { level: string; text: string }) => `<h${data.level}>${data.text}</h${data.level}>`,

            list: (data: { style: string; items: string[] }) => {
                const tagname = `${data.style.charAt(0)}l`;
                return `<${tagname}>${data.items.map(item => `<li>${item}</li>`)}</${tagname}>`;
            }
        };
    }

    render(ejsData: string): string {
        try {
            const parsed = JSON.parse(ejsData).blocks;
            return parsed.reduce((output: string, block: Block) => output + this.renderBlock(block), '');
        } catch (e) {
            return ejsData || '';
        }
    }

    renderBlock(block: Block): string {
        return `${HTMLEngine.generators[block.type](block.data)}\n`;
    }
}
