import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { RequireAuth } from './RequireAuth';

import { Login } from './pages/login';
import { Home } from './pages/home';
import { UserSubscription } from './pages/subscription';
import { Me } from './pages/me';

import './styles/index.css';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route element={<RequireAuth />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/subscription" element={<UserSubscription />} />
                        <Route path="/me" element={<Me />} />
                    </Route>
                </Routes>
            </Router>

            <ToastContainer
                key="ToastContainer"
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                closeButton={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover={false}
                theme="colored"
            />
        </>
    );
}
