import { ReactNode, useEffect } from 'react';
import {  useNavigate, Link } from 'react-router-dom';

import { useAuth } from '../contexts/auth';

import logo from '../assets/LOGO_BIO_2.png';

type LayoutProps = {
    title?: string;
    children: ReactNode;
};

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
    const { logout } = useAuth();
     const navigate =  useNavigate();

    useEffect(() => {
        document.title = `${props.title} - Consulta di Bioetica`;
    });

    const doLogout = async (): Promise<void> => {
        if (logout) {
            logout();
           navigate('/login');
        }
    };

    return (
        <div className="w-full xl:w-2/3 min-h-full mx-auto bg-white">
            <nav className="py-4 px-4 md:px-8">
                <ul className="flex h-full">
                    <li>
                        <Link to="/" className="w-full h-full pr-8 flex items-center justify-center">
                            <img src={logo} className="h-16" alt="Consulta di Bioetica" title="Consulta di Bioetica" />
                        </Link>
                    </li>
                    <li>
                        <Link to="/" className="p-6 flex flex-col items-center justify-center hover:bg-vdp-beige">
                            <i className="las la-home la-2x mr-1" />
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/subscription" className="p-6 flex flex-col items-center justify-center hover:bg-vdp-beige">
                            <i className="las la-wallet la-2x mr-1" />
                            Abbonamento
                        </Link>
                    </li>
                    <li>
                        <Link to="/me" className="p-6 flex flex-col items-center justify-center hover:bg-vdp-beige">
                            <i className="las la-user la-2x mr-1" />
                            Account
                        </Link>
                    </li>
                    <li className="flex-1"></li>
                    <li>
                        <button className="pl-8 h-full flex flex-col items-center justify-center" onClick={() => doLogout()}>
                            <i className="las la-sign-out-alt la-2x mr-1" />
                            Logout
                        </button>
                    </li>
                </ul>
            </nav>

            <section className="py-4 px-4 md:px-8">{props.children}</section>

            <footer className="py-8 px-4 md:px-8">
                <div className="py-2 text-sm text-center">
                    Consulta di Bioetica Onlus - Via Po 18 10123 Torino - P. I.V.A. 04694410962 C.F. 97362610152 - +39 393 0451364 - segreteria@consultadibioetica.org
                </div>
            </footer>
        </div>
    );
};
