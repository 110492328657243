import { ReactElement } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from './contexts/auth';

export const RequireAuth = (): ReactElement => {
    const { isAuthenticated, loading } = useAuth();
    const location = useLocation();

    if (loading) {
        return (
            <div className="h-full flex justify-center items-center text-green-400">
                <h3>Caricamento...</h3>
            </div>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" replace state={{ returnUrl: location.pathname }} />;
    }

    return <Outlet />;
};
