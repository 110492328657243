import { toast } from 'react-toastify';
import { CombinedError } from 'urql';

type HttpError = {
    code?: string;
    message?: string;
};

class Notification {
    success(message: string) {
        toast.success(message);
    }

    error(error: HttpError | CombinedError | string) {
        if (typeof error === 'string') {
            toast.error(this.tranlsate(error));
        } else {
            if (Object.keys(error).includes('graphQLErrors')) {
                (error as CombinedError).graphQLErrors?.forEach(e => toast.error(this.tranlsate((e.extensions?.code as string) || e.message)));
            } else {
                const e = error as HttpError;
                toast.error(this.tranlsate(e.code || e.message || 'Si è verificato un errore sconosciuto'));
            }
        }
    }

    tranlsate(error: string) {
        switch (error) {
            case 'auth/expired-action-code':
                return 'Il codice inviato è scaduto. Ricomincia la procedura';
            case 'auth/invalid-action-code':
                return 'Il codice di registrazione è errato, è scaduto o è già stato usato';
            case 'auth/user-disabled':
                return "L'utente è stato disabilitato dall'amministazione. Contattaci per maggiori informazioni";
            case 'auth/user-not-found':
                return 'Un utente con questo indirizzo email non esiste';
            case 'auth/weak-password':
                return 'la password non rispetta gli standard di sicurezza';
            case 'auth/invalid-email':
                return "L'indirizzo email specificato non è valido";
            case 'auth/wrong-password':
                return 'Password errata o utente disabilitato';
            case 'auth/email-already-in-use':
                return 'Un utente con questo indirizzo email esiste già';
            case 'auth/too-many-requests':
                return 'Troppi tentativi, attendere qualche minuti e riprovare';
            case 'auth/requires-recent-login':
                return "Questa operazione è delicata e richiede un'autenticazione recente. Accedi di nuovo prima di ritentare la richiesta";
            case 'UNAUTHENTICATED':
                return 'Sessione scaduta';
            case 'UNAUTHORIZED':
                return 'Non disponi dei diritti necessari per compiere questa azione';
            case 'JWT_AUTH_UNABLE':
                return 'Sessione scaduta';
            case 'USER_ID_NOT_FOUND':
                return 'Nessun utente attivo con queste credenziali';
            default:
                return error;
        }
    }
}

const notification = new Notification();
export default notification;
