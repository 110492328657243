import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseApp = initializeApp({
    apiKey: 'AIzaSyAJUucdfWzfYrF35WztGqt7jPPk2tvQlzM',
    authDomain: 'bioetica-subscribers.firebaseapp.com',
    databaseURL: 'https://bioetica-subscribers.firebaseio.com',
    projectId: 'bioetica-subscribers',
    storageBucket: 'bioetica-subscribers.appspot.com',
    messagingSenderId: '386637523814',
    appId: '1:386637523814:web:3bbf4f7a7644edaff236a7'
});

const auth = getAuth(firebaseApp);
export default auth;
